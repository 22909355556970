<template>
  <div class="card p-shadow-6">
    <h1>Suporte Mapa</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <div class="p-fluid p-grid">
      <Loading :active.sync="isLoading" />
      <div class="p-col-12 p-md-3" v-for="node in nodes" :key="node.key">
        <Tree
          selectionMode="checkbox"
          :value="node"
          :selectionKeys.sync="selectedNodes"
          @node-select="onNodeSelect"
          @node-unselect="onNodeUnselect"
        ></Tree>
      </div>
      <div class="p-col-12 p-mt-2 p-d-flex">
        <div class="p-col-12 p-md-2 p-mt-2">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-calendar"></i>
            </span>
            <span class="p-float-label">
              <v-date-picker
                v-model="dayStart"
                :masks="{
                  input: 'YYYY-MM-DD',
                }"
                @dayclick="dayStartChanged"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <span class="p-float-label">
                    <input
                      v-bind:class="[
                        'form-control',
                        'p-inputtext',
                        'p-component',
                        'p-filled',
                      ]"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                    <label for="inputtext">Dia Inicio</label>
                  </span>
                </template>
              </v-date-picker>
            </span>
          </div>
        </div>
        <div class="p-col-12 p-md-2 p-mt-2">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-calendar"></i>
            </span>
            <span class="p-float-label">
              <v-date-picker
                v-model="dayEnd"
                :masks="{
                  input: 'YYYY-MM-DD',
                }"
                @dayclick="dayEndChanged"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <span class="p-float-label">
                    <input
                      v-bind:class="[
                        'form-control',
                        'p-inputtext',
                        'p-component',
                        'p-filled',
                      ]"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                    <label for="inputtext">Dia Fim</label>
                  </span>
                </template>
              </v-date-picker>
            </span>
          </div>
        </div>
        <div class="p-col-12 p-md-1 p-mt-2">
          <span
            class="p-float-label"
            style="top: -0.75rem; left: -1rem; font-size: 12px"
          >
            <label for="private">Ver Detalhes</label>
          </span>
          <InputSwitch
            name="showDetails"
            v-model="showDetailsMarkers"
            class="p-mt-1"
          />
        </div>
        <div
          class="p-col-12 p-md-1 p-mt-2"
          v-if="loggedUser.isEsa || can('seeUsefulMarkersOnTheMap')"
        >
          <span
            class="p-float-label"
            style="top: -0.75rem; left: -1rem; font-size: 12px"
          >
            <label for="usefulMarkers">Marcadores Úteis</label>
          </span>
          <InputSwitch
            name="usefulMarkers"
            v-model="showExtraMarkers"
            class="p-mt-1"
          />
        </div>
        <div class="p-col-12 p-md-1 p-mt-2">
          <span
            class="p-float-label"
            style="top: -0.75rem; left: -1rem; font-size: 12px"
          >
            <label for="surveysUnmarked">Surv. Por Marcar</label>
          </span>
          <InputSwitch
            name="surveysUnmarked"
            v-model="surveysUnmarked"
            class="p-mt-1"
            @click="showButtonView = true"
          />
        </div>
        <div class="p-col-12 p-md-1 p-mt-2">
          <Button
            v-if="showButtonView"
            label="Ver"
            icon="pi pi-eye"
            class="p-button-success"
            @click="initMapTasks"
          />
        </div>
        <div class="p-col-12 p-md-2 p-mt-2"></div>
        <div class="p-col-12 p-md-2 p-mt-2">
          <Button
            v-if="loggedUser.isEsa || can('seeUsefulMarkers')"
            label="Marcadores Úteis"
            icon="pi pi-map-marker"
            class="p-button-primary"
            @click="extraMarkerDialog = true"
          />
        </div>
      </div>
      <div class="p-col-12 p-md-12">
        <Message v-if="markerModel.getCoordinates" severity="info" icon="pi-map"
          >Clique no mapa para obter as coordenadas para o marcador</Message
        >
      </div>
      <div class="p-col-12 p-md-10">
        <div style="height: 900px; width: 100%">
          <l-map
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            style="height: 80%"
            ref="myMap"
            :key="mapID"
            @click="getCoordinates"
          >
            <l-tile-layer :url="url" :attribution="attribution" />
            <v-geosearch :options="geosearchOptions"></v-geosearch>
            <l-marker
              v-for="(taskMarker, index) in simpleMarkers"
              :key="index"
              ref="markersTasks"
              :lat-lng="taskMarker.latLng ? taskMarker.latLng : center"
              :options="{ allUsersIds: taskMarker.allUsersIds, index: index }"
              :visible="!showDetailsMarkers"
            >
              <l-popup>
                <div>
                  <div
                    v-for="(tooltipText, i) in taskMarker.tooltipText"
                    :key="i"
                  >
                    <b>{{ tooltipText }}</b>
                  </div>
                </div>
              </l-popup>
            </l-marker>
            <v-marker-cluster ref="clusterRef" :options="{}">
              <l-marker
                v-for="(task, index) in tasksInMap"
                :key="index"
                :lat-lng="task.latLng ? task.latLng : center"
                :options="{ userId: task.userId, index: index }"
                :visible="showDetailsMarkers"
              >
                <l-icon
                  :icon-anchor="staticAnchor"
                  class-name="marker-class"
                  v-bind:style="{ backgroundColor: task.userColor }"
                >
                  <div
                    class="headline marker-number"
                    :style="{ backgroundColor: task.userColor }"
                  >
                    {{ task.position }}
                  </div>
                  <div class="headline">
                    {{ task.username }}
                    {{ task.start }} - {{ task.end }}
                  </div>
                </l-icon>
                <l-popup>
                  <div>
                    <div>
                      <b>{{ task.username }}</b> {{ task.start }} -
                      {{ task.end }}
                    </div>
                    <div><b>PAT: </b> {{ task.pat }} - {{ task.patTitle }}</div>
                    <div><b>Projeto: </b> {{ task.projectName }}</div>
                  </div>
                </l-popup>
              </l-marker>
            </v-marker-cluster>
            <l-marker
              v-for="(extraMarker, index) in extraMarkersList"
              :key="index + Math.random()"
              :lat-lng="getLatLog(extraMarker)"
              ref="extraMarkersy"
              :visible="showExtraMarkers && extraMarker.marker == 1"
            >
              <l-icon
                :icon-anchor="staticAnchor"
                :icon-size="[37, 39]"
                icon-url="https://icons.iconarchive.com/icons/icons-land/vista-map-markers/32/Map-Marker-Push-Pin-1-Chartreuse-icon.png"
              >
              </l-icon>
              <l-popup>
                <div>
                  <div>
                    <b>{{ extraMarker.name }}</b>
                  </div>
                </div>
              </l-popup>
            </l-marker>
            <l-marker
              v-for="(survey, index) in surveysUnmarkedList"
              :key="index + Math.random()"
              :lat-lng="survey.latLng ? survey.latLng : center"
              ref="markersSurvey"
              @click="surveyPopUpGo(index)"
            >
              <l-icon
                :icon-anchor="staticAnchor"
                :icon-size="[37, 39]"
                icon-url="http://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/map-marker-icon.png"
              >
              </l-icon>
              <l-popup>
                <div>
                  <div>
                    <b>{{ survey.agname }}</b
                    ><br />
                    {{ survey.name }}
                    <br />
                    {{ survey.survey.status }}
                  </div>
                </div>
              </l-popup>
            </l-marker>
            <l-circle
              v-for="(extraMarker, index) in extraMarkersList"
              :key="index + Math.random()"
              :visible="showExtraMarkers && extraMarker.marker !== 1"
              :lat-lng="getLatLog(extraMarker)"
              :radius="2000"
              :color="'#7DA92F'"
            >
              <l-popup>
                <div>
                  <div>
                    <b>{{ extraMarker.name }}</b>
                  </div>
                </div>
              </l-popup>
            </l-circle>
          </l-map>
        </div>
      </div>
      <div class="p-col-12 p-md-2" style="overflow: auto; max-height: 750px">
        <div
          class="p-col-12 p-md-12"
          v-if="surveysUnmarkedList.length > 0"
          id="surveys-list"
        >
          <h5>Surveys por marcar</h5>
          <divider />
          <div
            class="p-col-12 p-md-12 list-border p-mt-1"
            v-for="(survey, index) in surveysUnmarkedList"
            :key="survey.id"
            v-bind:id="`survey-${index}`"
          >
            <b>Nome: </b>{{ survey.agname }} <br />
            <b>Resumo: </b>{{ survey.resume }}<br />
            <b>Estado: </b>{{ survey.survey.status }}<br />
            <b>Morada: </b><br />
            {{ survey.localization.address }}<br />
            {{ survey.localization.local }}<br />
            {{ survey.localization.zip_code }}<br />
            <div class="p-fluid p-grid">
              <div class="p-col-6 p-md-6">
                <Button
                  label="Mais info"
                  @click="goPatInfo(survey.pat)"
                  class="p-button-sm"
                />
              </div>
              <div class="p-col-6 p-md-6">
                <Button
                  label="Ver"
                  @click="displaySurveyTooltip(index)"
                  class="p-button-sm"
                />
              </div>
            </div>
          </div>
          <divider />
        </div>
        <div
          class="p-col-12 p-md-12"
          v-if="tasksInMap.length > 0"
          id="tasks-list"
        >
          <h5>Marcações</h5>
          <divider />
          <div
            class="p-col-12 p-md-12 list-border p-mt-1"
            v-for="(task, index) in tasksInMap"
            :key="task.id"
            v-bind:id="`task-${index}`"
          >
            <b>Nome: </b>{{ task.username }} <br />

            <span v-if="task.isBooking == undefined"
              ><b>Projecto: </b>{{ task.projectName }}</span
            >
            <span v-else><b>Reservado </b></span><br />
            {{ task.start }} - {{ task.end }}<br />
            <span v-if="task.isBooking == undefined"
              ><b>Titulo: </b>{{ task.patTitle }}</span
            ><br />
            <div class="p-fluid p-grid">
              <div class="p-col-6 p-md-6">
                <Button
                  v-if="task.isBooking == undefined"
                  label="Mais info"
                  @click="goPatInfo(task.pat)"
                  class="p-button-sm"
                />
              </div>
              <div class="p-col-6 p-md-6">
                <Button
                  label="Ver"
                  @click="displayTaskTooltip(index)"
                  class="p-button-sm"
                />
              </div>
              <Divider type="dashed" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      id="'extraMarker'"
      :visible.sync="extraMarkerDialog"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :modal="true"
      :position="'top'"
      :header="'Marcadores Úteis'"
      @hide="extraMarkerHide"
    >
      <DataTable
        v-if="!markerForm"
        :value="extraMarkersList"
        :loading="false"
        :paginator="false"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        dataKey="id"
      >
        <template #header>
          <div class="table-header">
            <Button
              v-if="loggedUser.isEsa || can('addUsefulMarkers')"
              icon="pi pi-plus"
              @click="newMarker()"
            />
          </div>
        </template>
        <template #empty> Não existem marcadores. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column field="name" header="Nome" sortable>
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>
        </Column>
        <Column field="marker" header="Tipo" sortable>
          <template #body="slotProps">
            {{ slotProps.data.marker == 1 ? "Marcador" : "Zona" }}
          </template>
        </Column>
        <Column field="created_user" header="Autor" sortable>
          <template #body="slotProps">
            {{ slotProps.data.created_user }}
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="can('updateUsefulMarkers')"
              icon="pi pi-pencil"
              class="p-button-sm p-button-rounded p-button-success p-button-text"
              @click="editMarker(slotProps)"
            />
            <Button
              v-if="can('deleteUsefulMarkers')"
              icon="pi pi-trash"
              class="p-button-sm p-button-rounded p-button-danger p-button-text p-ml-2"
              @click="deleteMarker(slotProps)"
            />
          </template>
        </Column>
      </DataTable>

      <form
        v-if="markerForm"
        name="mapMarkerForm"
        class="p-col-12"
        @submit.prevent="saveMarker"
        style="min-height: 50vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <InputText
                :name="'markerName'"
                type="text"
                v-validate="'required'"
                v-model="markerModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('markerName') },
                  'form-control',
                ]"
              />
              <label for="markerName">Nome</label>
            </span>
            <small v-if="errors.has('markerName')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <Dropdown
                v-model="markerModel.marker"
                name="markerType"
                id="markerType"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('markerType') },
                  'form-control',
                ]"
                :options="[
                  { type: 1, label: 'Marcador' },
                  { type: 2, label: 'Zona' },
                ]"
                optionValue="type"
                optionLabel="label"
                :dataKey="'type'"
              />
              <label for="markerType">Tipo</label>
            </span>
            <small v-if="errors.has('markerType')" class="p-error" role="alert">
              Tipo é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="markerPublic">Publico</label>
            </span>
            <Dropdown
              v-model="markerModel.public"
              name="markerPublic"
              v-validate="'required'"
              v-bind:class="[
                { 'p-invalid': errors.has('markerPublic') },
                'form-control',
              ]"
              :options="[
                { type: 1, label: 'Sim' },
                { type: 0, label: 'Não' },
              ]"
              optionValue="type"
              optionLabel="label"
              :dataKey="'type'"
            />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <InputText
                :name="'markerLat'"
                type="text"
                v-validate="'required'"
                :readonly="true"
                v-model="markerModel.latitude"
                v-bind:class="[
                  { 'p-invalid': errors.has('markerLat') },
                  'form-control',
                ]"
              />
              <label for="markerLat">Latitude</label>
            </span>
            <small v-if="errors.has('markerLat')" class="p-error" role="alert">
              Latitude é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <span class="p-float-label">
              <InputText
                :name="'markerLng'"
                type="text"
                v-validate="'required'"
                :readonly="true"
                v-model="markerModel.latitude"
                v-bind:class="[
                  { 'p-invalid': errors.has('markerLng') },
                  'form-control',
                ]"
              />
              <label for="markerLng">Longitude</label>
            </span>
            <small v-if="errors.has('markerLng')" class="p-error" role="alert">
              Longitude é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <Button
              label="Picar Coordenadas no Mapa"
              icon="pi pi-map"
              class="p-button-primary"
              @click="pickCoordinates"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="markerForm"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveMarker"
        />
        <Button
          v-if="markerForm"
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancelMarker"
        />
        <Button
          v-if="extraMarkerDialog && !markerForm"
          label="Fechar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="extraMarkerHide"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import taskService from "../services/task.service";
import "vue-loading-overlay/dist/vue-loading.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet-geosearch/assets/css/leaflet.css";

import Loading from "vue-loading-overlay";
import { latLng, Icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LCircle,
} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import markersService from "../services/mapMarkers.service";
import surveysService from "../services/surveys.service";
import patsService from "../services/pats.service";
export default {
  name: "SupportMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    Loading,
    LCircle,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    "v-geosearch": VGeosearch,
  },
  data() {
    return {
      userId: null,
      loggedUser: null,
      myUsers: [],
      isLoading: true,
      nodes: [],
      selectedNodes: {},
      userTasksInMap: {},
      zoom: 6,
      center: latLng(0, 0),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: null,
      mapOptions: {
        zoomSnap: 0.5,
      },
      staticAnchor: [16, 37],
      showMap: false,
      dayStart: null,
      dayEnd: null,
      showButtonView: false,
      tasksInMap: [],
      map: null,
      simpleMarkers: [],
      showDetailsMarkers: false,
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
        // style: "bar",
        showMarker: true,
        showPopup: false,
        autoClose: true,
        retainZoomLevel: false,
        animateZoom: true,
        keepResult: false,
        searchLabel: "Pesquisar localidade",
      },
      mapID: Math.random(),
      showExtraMarkers: true,
      extraMarkersList: [],
      extraMarkerDialog: false,
      markerForm: false,
      markerModel: {},
      usefulMarkers: false,
      surveysUnmarkedList: [],
      surveysUnmarked: false,
    };
  },
  beforeCreate() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  },
  async created() {
    this.isLoading = true;
    this.dayStart =
      this.$route.params.date != undefined
        ? this.$route.params.date
        : getOnlyDate(new Date());
    this.dayEnd =
      this.$route.params.date != undefined
        ? this.$route.params.date
        : getOnlyDate(new Date());
    this.userId = this.$store.state.auth.user.id;
    this.loggedUser = this.$store.state.auth.user;
    if (!this.can("seeUsefulMarkersOnTheMap") && !this.loggedUser.isEsa) {
      this.showExtraMarkers = false;
    }
    this.dataToTree();
    if (this.loggedUser.isEsa || this.can("seeUsefulMarkersOnTheMap")) {
      this.extraMarkersList = await markersService.getMarkers();
    }
    this.isLoading = false;
  },
  beforeMount() {},
  async mounted() {
    this.map = this.$refs.myMap.mapObject;
    this.initMapTasks();
  },
  methods: {
    dataToTree() {
      let hasLocal = false;
      if (sessionStorage.selectedNodes != undefined) {
        hasLocal = true;
        this.selectedNodes = JSON.parse(sessionStorage.selectedNodes);
      }
      for (const [office, user] of Object.entries(
        this.$store.state.auth.user.supportUsers
      )) {
        let users = [];

        user.forEach((myUser) => {
          this.myUsers.push({
            id: myUser.id,
            username: myUser.username,
            office: office,
            userColor: myUser.userColor,
            officeColor: myUser.officeColor,
            technicianInitials: myUser.technicianInitials,
          });
          users.push({
            key: myUser.id,
            label: myUser.username,
            data: `${office} Office User`,
            icon: "pi pi-fw pi-user",
            userColor: myUser.userColor,
            officeColor: myUser.officeColor,
            technicianInitials: myUser.technicianInitials,
          });

          if (!hasLocal) {
            this.selectedNodes[myUser.id] = {
              checked: true,
              partialChecked: false,
            };
          }
          this.userTasksInMap[myUser.id] = {
            inCalendar:
              this.selectedNodes[myUser.id] != undefined
                ? this.selectedNodes[myUser.id].checked
                : false,
            userId: myUser.id,
            userColor: myUser.userColor,
            officeColor: myUser.officeColor,
            username: myUser.username,
            userOffice: myUser.office,
            technicianInitials: myUser.technicianInitials,
            tasks: [],
          };
        });

        let tree = {
          key: office,
          label: office,
          data: `office`,
          icon: "pi pi-fw pi-home",
          children: users,
        };
        this.nodes.push({ tree });
        if (!hasLocal) {
          this.selectedNodes[office] = {
            checked: true,
            partialChecked: false,
          };
        }
      }
      if (!hasLocal) {
        sessionStorage.selectedNodes = JSON.stringify(this.selectedNodes);
      }
    },
    async getUserTasks(dateStart, dateEnd, userId) {
      const exist = (element) =>
        this.simpleMarkers.findIndex(
          (elemt) => elemt.latLngId == element.latLngId
        );
      return taskService
        .getTasksAndBookings(userId, dateStart, dateEnd)
        .then((days) => {
          days.map(async (day) => {
            day.tasks.map(async (task, index) => {
              if (!this.currentCenter && task.localization != undefined) {
                this.currentCenter = latLng(
                  task.localization.latitude,
                  task.localization.longitude
                );
                this.center = latLng(
                  task.localization.latitude,
                  task.localization.longitude
                );
              }
              let date =
                task.isBooking != undefined && task.isBooking == true
                  ? task.date
                  : getOnlyDate(new Date(task.start));
              let startTime =
                task.isBooking != undefined && task.isBooking == true
                  ? task.start
                  : getOnlyTime(new Date(task.start));
              let endTime =
                task.isBooking != undefined && task.isBooking == true
                  ? task.end
                  : getOnlyTime(new Date(task.end));
              let newTask = {
                id: task.id,
                latLngId: `${String(
                  task.localization ? task.localization.latitude : 0
                )}_${String(
                  task.localization ? task.localization.longitude : 0
                )}`,
                userId: parseInt(userId),
                position: index + 1,
                latLng: latLng(
                  task.localization ? task.localization.latitude : 0,
                  task.localization ? task.localization.longitude : 0
                ),
                username: task.technician,
                userColor: this.userTasksInMap[userId].userColor,
                isBooking: task.isBooking,
                start: startTime,
                end: endTime,
                pat: task.pat_number,
                patTitle: task.pat_title,
                projectName: task.project_name,
                tooltipText: [
                  `${task.technician}: ${date} | ${startTime} - ${endTime}`,
                ],
                allUsersIds: [parseInt(userId)],
              };
              this.tasksInMap.push(newTask);

              let existIndex = exist(newTask);
              if (existIndex < 0) {
                this.simpleMarkers.push(newTask);
              } else {
                this.simpleMarkers[
                  existIndex
                ].username += ` | ${newTask.username}`;
                this.simpleMarkers[existIndex].allUsersIds.push(newTask.userId);
                this.simpleMarkers[existIndex].tooltipText.push(
                  newTask.tooltipText[0]
                );
              }
            });
          });

          return;
        });
    },
    async onNodeSelect(node) {
      sessionStorage.selectedNodes = JSON.stringify(this.selectedNodes);
      if (node.data == "office") {
        this.$store.state.auth.user.supportUsers[node.key].map(async (user) => {
          if (
            this.userTasksInMap[user.id] == undefined ||
            this.userTasksInMap[user.id].inCalendar == false
          ) {
            this.userTasksInMap[user.id] = {
              inCalendar: true,
              userId: user.id,
              userColor: user.userColor,
              officeColor: user.officeColor,
              username: user.username,
              userOffice: user.office,
              technicianInitials: user.technicianInitials,
            };
            this.showButtonView = true;
          }
        });
      } else if (
        this.userTasksInMap[node.key] == undefined ||
        this.userTasksInMap[node.key].inCalendar == false
      ) {
        this.userTasksInMap[node.key] = {
          inCalendar: true,
          userId: node.key,
          userColor: node.userColor,
          officeColor: node.officeColor,
        };
        this.showButtonView = true;
      }
    },
    async onNodeUnselect(node) {
      sessionStorage.selectedNodes = JSON.stringify(this.selectedNodes);
      const removeTasks = async (userId) => {
        this.userTasksInMap[userId].inCalendar = false;
      };
      if (node.data == "office") {
        this.isLoading = true;
        this.$store.state.auth.user.supportUsers[node.key].map(async (user) => {
          await removeTasks(user.id);
        });
        this.isLoading = false;
      } else {
        this.isLoading = true;
        await removeTasks(node.key);
        this.isLoading = false;
      }
      this.showButtonView = true;
    },
    initMapTasks() {
      this.isLoading = true;
      this.showButtonView = false;
      this.simpleMarkers = [];
      this.tasksInMap = [];
      this.mapID = Math.random();
      let promises = [];
      Object.entries(this.userTasksInMap).forEach(
        async ([userId, userInfo]) => {
          if (userInfo.inCalendar) {
            await promises.push(
              this.getUserTasks(this.dayStart, this.dayEnd, userId)
            );
          }
        }
      );
      if (this.surveysUnmarked) {
        promises.push(this.getSurveysUnmarked());
      }
      Promise.all(promises).then(() => {
        this.isLoading = false;
        return (this.showMap = true);
      });
    },
    dayStartChanged(date) {
      this.dayStart = date.id;
      this.currentCenter = null;
      return (this.showButtonView = true);
    },
    dayEndChanged(date) {
      this.dayEnd = date.id;
      this.currentCenter = null;
      return (this.showButtonView = true);
    },
    getLatLog(marker) {
      return latLng(marker.latitude, marker.longitude);
    },
    extraMarkerHide() {
      if (this.markerModel.getCoordinates) {
        return;
      }
      this.$validator.pause();
      this.$validator.reset();
      this.markerModel = {};

      this.extraMarkerDialog = false;
      this.markerForm = false;
    },
    newMarker() {
      this.markerModel = { public: 1 };
      return (this.markerForm = true);
    },
    editMarker(marker) {
      this.markerModel = { ...marker.data };
      this.markerModel.originalMarker = { ...marker };
      this.markerForm = true;
    },
    cancelMarker() {
      this.$validator.pause();
      this.$validator.reset();
      this.markerModel = {};

      this.markerForm = false;
    },
    getCoordinates(event) {
      if (this.markerForm && this.markerModel.getCoordinates) {
        this.markerModel.latitude = event.latlng.lat;
        this.markerModel.longitude = event.latlng.lng;
        this.markerModel.getCoordinates = false;
        return (this.extraMarkerDialog = true);
      }
      return;
    },
    pickCoordinates() {
      this.markerModel.getCoordinates = true;
      return (this.extraMarkerDialog = false);
    },
    saveMarker() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let bodyParam = {
          name: this.markerModel.name,
          latitude: this.markerModel.latitude,
          longitude: this.markerModel.longitude,
          marker: this.markerModel.marker,
          public: this.markerModel.public,
        };
        if (this.markerModel.id === undefined) {
          return this.createMarker(bodyParam);
        }
        return this.updateMarker(bodyParam);
      });
    },
    createMarker(bodyParam) {
      return markersService.createMarker(bodyParam).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao criar o marcador",
            life: 3000,
          });
        }
        this.extraMarkersList.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Marcador criado",
          detail: "O Marcador foi criado com sucesso",
          life: 3000,
        });
        return this.cancelMarker();
      });
    },
    updateMarker(bodyParam) {
      return markersService
        .updateMarker(this.markerModel.id, bodyParam)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao editar o marcador",
              life: 3000,
            });
          }
          this.extraMarkersList[this.markerModel.originalMarker.index] =
            response;
          this.$toast.add({
            severity: "success",
            summary: "Marcador editado",
            detail: "O Marcador foi editado com sucesso",
            life: 3000,
          });
          return this.cancelMarker();
        });
    },
    deleteMarker(marker) {
      this.$confirm.require({
        message: "Tem a certeza que pretende eliminar este marcador?",
        header: `Eliminar marcador ${marker.data.name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          markersService.deleteMarker(marker.data.id).then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao eliminar o marcador",
                life: 3000,
              });
            }
            return this.extraMarkersList.splice(marker.index, 1);
          });
        },
      });
    },
    goPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`,
      });
      window.open(route.href, "_blank");
    },
    displaySurveyTooltip(selectedIndex) {
      let markerObjects = this.$refs.markersSurvey.map((ref) => ref.mapObject);
      for (let markerObject of markerObjects) {
        markerObject.closePopup();
      }
      markerObjects[selectedIndex].togglePopup();
      let latLng = markerObjects[selectedIndex].getLatLng();
      this.$refs.myMap.mapObject.setView(latLng, 11);
    },
    surveyPopUpGo(index) {
      let marked = document.querySelector("#surveys-list .marked");
      if (marked != undefined) {
        marked.classList.remove("marked");
      }
      document.querySelector(`#survey-${index}`).classList.add("marked");
    },
    displayTaskTooltip(selectedIndex) {
      let markerObjects = this.$refs.markersTasks.map((ref) => ref.mapObject);
      for (let markerObject of markerObjects) {
        markerObject.closePopup();
      }
      markerObjects[selectedIndex].togglePopup();
      let latLng = markerObjects[selectedIndex].getLatLng();
      this.$refs.myMap.mapObject.setView(latLng, 11);
    },
    taskPopUpGo(index) {
      let marked = document.querySelector("#tasks-list .marked");
      if (marked != undefined) {
        marked.classList.remove("marked");
      }
      document.querySelector(`#task-${index}`).classList.add("marked");
    },
    async getSurveysUnmarked() {
      this.surveysUnmarkedList = [];
      await surveysService.getToMark().then((response) => {
        return response.forEach((survey) => {
          return patsService.getPatInfo(survey.id).then((patInfo) => {
            patInfo.type = "survey";
            if (!this.currentCenter) {
              this.currentCenter = latLng(
                patInfo.localization.latitude,
                patInfo.localization.longitude
              );
              this.center = latLng(
                patInfo.localization.latitude,
                patInfo.localization.longitude
              );
            }
            patInfo.latLng = latLng(
              patInfo.localization.latitude,
              patInfo.localization.longitude
            );
            return this.surveysUnmarkedList.push(patInfo);
          });
        });
      });
      await surveysService.getAttemptSchedule().then((response) => {
        return response.forEach((survey) => {
          return patsService.getPatInfo(survey.id).then((patInfo) => {
            patInfo.type = "survey";
            if (!this.currentCenter) {
              this.currentCenter = latLng(
                patInfo.localization.latitude,
                patInfo.localization.longitude
              );
              this.center = latLng(
                patInfo.localization.latitude,
                patInfo.localization.longitude
              );
            }
            patInfo.latLng = latLng(
              patInfo.localization.latitude,
              patInfo.localization.longitude
            );
            return this.surveysUnmarkedList.push(patInfo);
          });
        });
      });
      await surveysService.getToMarkContactManager().then((response) => {
        return response.forEach((survey) => {
          return patsService.getPatInfo(survey.id).then((patInfo) => {
            patInfo.type = "survey";
            if (!this.currentCenter) {
              this.currentCenter = latLng(
                patInfo.localization.latitude,
                patInfo.localization.longitude
              );
              this.center = latLng(
                patInfo.localization.latitude,
                patInfo.localization.longitude
              );
            }
            patInfo.latLng = latLng(
              patInfo.localization.latitude,
              patInfo.localization.longitude
            );
            return this.surveysUnmarkedList.push(patInfo);
          });
        });
      });
    },
  },
};
</script>

<style>
.pi-chevron-down::before {
  color: #6c757d !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #fff;
  color: #000;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #ced4da;
  background: #fff;
}
.p-tree-container .pi-check:before {
  color: black;
}
.p-tree-container .pi-user:before {
  color: #6c757d;
}
.p-tree-container .pi-home:before {
  color: #6c757d;
}
.p-tree-container .pi-chevron-right:before {
  color: #6c757d;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.marker-class {
  background-color: #ee440f;
  color: #fff;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 0 20px 20px 20px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  font-weight: bold;
}
.marker-number {
  border: 1px solid #fff;
}
.vc-popover-content-wrapper {
  z-index: 99999 !important;
}
</style>
