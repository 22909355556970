import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class TaskService {
  getTasks(userId, start, end) {
    return axios
      .get(
        `${config["apiUrl"]}intervention/task/user/${userId}/${start}/${end}`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      }).catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createTask($bodyParms) {
    return axios
      .post(`${config["apiUrl"]}intervention/task`, $bodyParms, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        if (error.response.status == 409) {
          console.log("ERROR:: ", error.response.data);
          return error.response;
        }
        console.log("ERROR:: ", error.response.data);
        return null
      });
  }

  updatedTask(taskId, $bodyParms) {
    return axios
      .patch(`${config["apiUrl"]}intervention/task/${taskId}`, $bodyParms, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null
      });
  }

  removeTask(taskId, typeId) {
    return axios
      .delete(`${config["apiUrl"]}intervention/task/${taskId}/${typeId}`, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null
      });
  }

  getTasksAndBookings(userId, start, end) {
    return axios
      .get(
        `${config["apiUrl"]}intervention/user/${userId}/start/${start}/end/${end}`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      }).catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getTasksInstallationSheet(taskId) {
    return axios
      .get(
        `${config["apiUrl"]}intervention/installationSheet/task/${taskId}`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      }).catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  lastTaskDate(userId) {
    return axios
      .get(
        `${config["apiUrl"]}intervention/user/${userId}/lastTaskDate`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      }).catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}

export default new TaskService();
