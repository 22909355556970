export function getOnlyDate(date) {
  let month = `${date.getMonth() + 1}`,
    day = `${date.getDate()}`,
    year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
}

export function getOnlyDateUserFormat(date) {
  let month = `${date.getMonth() + 1}`,
    day = `${date.getDate()}`,
    year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [day, month, year].join("-");
}

export function getOnlyTime(date) {
  let hours = `${date.getHours()}`,
    minutes = `${date.getMinutes()}`;

  if (hours.length < 2) hours = `0${hours}`;
  if (minutes.length < 2) minutes = `0${minutes}`;

  return `${hours}:${minutes}`;
}

export function isDateBeforeToday(day) {
  return new Date(day) < new Date(new Date().toDateString());
}

export function isToday(day) {
  let today = new Date();
  return (
    day.getDate() == today.getDate() &&
    day.getMonth() == today.getMonth() &&
    day.getFullYear() == today.getFullYear()
  );
}

export function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export function formatMinutes(minutesTotal) {
  let hours = minutesTotal / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);

  if (rhours < 10) {
    rhours = `0${rhours}`;
  }
  if (rminutes < 10) {
    rminutes = `0${rminutes}`;
  }
  return `${rhours}h${rminutes}m`;
}

export function getMonthList() {
  return [
    { month: 1, name: "Janeiro" },
    { month: 2, name: "Fevereiro" },
    { month: 3, name: "Março" },
    { month: 4, name: "Abril" },
    { month: 5, name: "Maio" },
    { month: 6, name: "Junho" },
    { month: 7, name: "Julho" },
    { month: 8, name: "Agosto" },
    { month: 9, name: "Setembro" },
    { month: 10, name: "Outubro" },
    { month: 11, name: "Novembro" },
    { month: 12, name: "Dezembro" },
  ];
}

export function getDaysList(year, month) {
  let monthNumberDays = new Date(year, month, 0).getDate();
  let day = 1;
  let daysList = [];

  while (day <= monthNumberDays) {
    daysList.push({ day: day++ });
  }
  return daysList;
}

export async function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export async function getRandomColorArray(number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      let result = [];
      const letters = "0123456789ABCDEF";
      for (let j = 1; j <= number; j++) {
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        result.push(color);
      }
      resolve(result);
    }, 100);
  });
}

export function formatNumber(value) {
  let number = parseInt(value);

  return number.toLocaleString("de-DE");
  // let formatado = value.toFixed(0).replace(".", ",");
  // formatado = formatado.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  // return formatado;
}
